import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col} from "react-bootstrap"


class Attributions extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About" />
        <Container>
          <Row>
            <Col sm={12}>
              <h1 className="page-title my-4">Attributions</h1>
            </Col>
          </Row>
          <br />
        <Row>
            <Col sm={12}>
            <h3>This website uses resources from the following places:</h3>
            <ul>
                <li><a href="https://www.vecteezy.com/">Vecteezy</a></li>
            </ul>
            </Col>
        </Row>
        </Container>
      </Layout>
    )
  }
}

export default Attributions

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
